import { Bars3Icon } from '@heroicons/react/20/solid';
import { cssBundleHref } from '@remix-run/css-bundle';
import {
  json,
  type LinksFunction,
  type MetaFunction,
  type LoaderFunctionArgs,
  type SerializeFrom } from
'@remix-run/node';
import {
  Link,
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation,
  useMatches } from
'@remix-run/react';
import { withSentry } from '@sentry/remix';
import { useEffect } from 'react';
import { AuthenticityTokenProvider } from 'remix-utils/csrf/react';
import { HoneypotProvider } from 'remix-utils/honeypot/react';
import { Confetti } from './components/confetti.tsx';
import { GeneralErrorBoundary } from './components/error-boundary.tsx';
import { LoadGtmScript } from './components/gtm-component.js';
import { EpicProgress } from './components/progress-bar.tsx';
import { EpicToaster } from './components/toaster.tsx';
import { Button } from './components/ui/button.tsx';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger } from
'./components/ui/dropdown-menu.tsx';
import { href as iconsHref } from './components/ui/icon.tsx';
import fontStyleSheetUrl from './styles/font.css';
import tailwindStyleSheetUrl from './styles/tailwind.css';
import { getConfetti } from './utils/confetti.server.ts';
import { csrf } from './utils/csrf.server.ts';
import { getEnv } from './utils/env.server.ts';
import * as gtag from './utils/gtag.clients.js';
import { honeypot } from './utils/honeypot.server.ts';
import { combineHeaders, getDomainUrl } from './utils/misc.tsx';
import { useNonce } from './utils/nonce-provider.ts';
import { getToast } from './utils/toast.server.ts';

export const links: LinksFunction = () => {
  return [
  // Preload svg sprite as a resource to avoid render blocking
  { rel: 'preload', href: iconsHref, as: 'image' },
  // Preload CSS as a resource to avoid render blocking
  { rel: 'preload', href: fontStyleSheetUrl, as: 'style' },
  { rel: 'preload', href: tailwindStyleSheetUrl, as: 'style' },
  cssBundleHref ? { rel: 'preload', href: cssBundleHref, as: 'style' } : null,
  { rel: 'stylesheet', href: fontStyleSheetUrl },
  { rel: 'stylesheet', href: tailwindStyleSheetUrl },
  cssBundleHref ? { rel: 'stylesheet', href: cssBundleHref } : null].
  filter(Boolean);
};

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  return [
  { title: data ? 'WizWorld' : 'Error | WizWorld' },
  {
    name: 'description',
    content: `Pick your favorite professional and get your maximum refund. It’s that simple!`
  },

  { property: 'twitter:card', content: 'summary_large_image' },
  { property: 'twitter:site', content: '@taxxwiz' },
  { property: 'twitter:creator', content: '@taxxwiz' },
  { property: 'og:image:width', content: '1200' },
  { property: 'og:image:height', content: '627' },
  { property: 'og:image:type', content: 'image/jpg' },
  {
    property: 'og:image',
    content:
    'https://imagedelivery.net/7zP8oR1_JaLiUKn9Wc0j5g/934be4f0-9c28-4d6a-1bf9-97cf011e0500/public'
  },
  {
    property: 'og:url',
    content: `${data?.requestInfo.origin}${data?.requestInfo.path}`
  },
  {
    property: 'og:site_name',
    content: 'SmartWiz'
  }];

};


export async function loader({ request }: LoaderFunctionArgs) {
  const { toast, headers: toastHeaders } = await getToast(request);
  const { confettiId, headers: confettiHeaders } = getConfetti(request);
  const honeyProps = honeypot.getInputProps();
  const [csrfToken, csrfCookieHeader] = await csrf.commitToken();

  return json(
    {
      requestInfo: {
        origin: getDomainUrl(request),
        path: new URL(request.url).pathname
      },
      ENV: getEnv(),
      toast,
      confettiId,
      honeyProps,
      csrfToken
    },
    {
      headers: combineHeaders(
        toastHeaders,
        confettiHeaders,
        csrfCookieHeader ? { 'set-cookie': csrfCookieHeader } : null
      )
    }
  );
}

export type RootLoaderData = SerializeFrom<typeof loader>;

function Document({
  children,
  nonce,
  env = {}




}: {children: React.ReactNode;nonce: string;env?: Record<string, string>;}) {
  return (
    <html lang="en" className="h-full overflow-x-hidden">
			<head>
				<Meta />
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width,initial-scale=1" />
				<meta
          name="facebook-domain-verification"
          content="ce1l2mrrxx4vbqun6g6p9uswedsq9i" />

				<link rel="preconnect" href="https://fonts.googleapis.com" />
				<meta
          name="facebook-domain-verification"
          content="ce1l2mrrxx4vbqun6g6p9uswedsq9i" />

				<link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="" />

				<link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@800&family=Lexend:wght@400;600;700&display=swap"
          rel="stylesheet">
        </link>
				<script
          dangerouslySetInnerHTML={{
            __html:
            "(function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');"
          }} />

				<LoadGtmScript
          gtmTrackingId="GTM-KM2G98KC"
          env={`${JSON.stringify(env)}`} />

				<script
          async
          src="https://r.wdfl.co/rw.js"
          data-rewardful="1e6e94">
        </script>
				<Links />
			</head>
			<body className="bg-background text-foreground">
				<script
          async
          id="gtag-init"
          dangerouslySetInnerHTML={{
            __html: `
					window.dataLayer = window.dataLayer || [];
					function gtag(){dataLayer.push(arguments);}
					gtag('js', new Date());
					`
          }} />

				{children}
				<script
          nonce={nonce}
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(env)}`
          }} />

				<ScrollRestoration nonce={nonce} />
				<Scripts nonce={nonce} />
				<LiveReload nonce={nonce} />
			</body>
		</html>);

}

function App() {
  const data = useLoaderData<typeof loader>();
  const nonce = useNonce();
  const matches = useMatches();
  const location = useLocation();

  const hideNav = matches.some((route) => {
    const handle = (route.handle as undefined | Record<string, any>);
    return handle?.showNav === false;
  });

  const hideHeader = location.pathname == '/welcome';

  const hideJoinAcademyButton = location.pathname === '/anthony-oneal' || location.pathname === '/checkout/anthony';
  const hideGetMyRefundButton = location.pathname === '/anthony-oneal' || location.pathname === '/checkout/anthony';

  useEffect(() => {
    gtag.pageview(location.pathname, 'GTM-KM2G98KC');
  }, [location]);

  return (
    <Document nonce={nonce} env={data.ENV}>
			<div className="flex h-screen flex-col justify-between font-sans text-secondary-foreground">
				<header className="container py-6">
					<nav className="flex w-full items-center justify-between">
						{hideHeader ?
            <></> :

            <h4 className="flex items-center gap-2 font-display text-lg md:text-2xl">
								<span className="font-bold">WIZWORLD</span>
								<span className="flex items-center justify-center  rounded bg-gradient-to-br from-brand-600 via-brand-500 to-brand-400 px-1.5 text-sm font-semibold text-white md:text-lg">
									MARKETPLACE
								</span>
							</h4>}

						{hideNav || hideHeader ? null :
            <>
								<div className="hidden items-center gap-4 sm:flex">
                                    {!hideGetMyRefundButton &&
                <Link to="/#signup" className="hover:underline">
                                            Get My Refund
                                        </Link>}

                                    {!hideJoinAcademyButton &&
                <Button asChild variant="outline">
                                            <Link to="/academy">Join The Academy</Link>
                                        </Button>}

                                </div>

								<div className="sm:hidden">
									<DropdownMenu>
										<DropdownMenuTrigger>
											<Bars3Icon className="mb-0.5 inline-block w-5" />
										</DropdownMenuTrigger>
										<DropdownMenuContent className="p-6">
											<DropdownMenuItem>
												<Button asChild>
													<Link to="/#signup">Get My Refund</Link>
												</Button>
											</DropdownMenuItem>
											<DropdownMenuItem>
												<Button asChild variant="outline">
													<Link to="/academy">Join The Academy</Link>
												</Button>
											</DropdownMenuItem>
										</DropdownMenuContent>
									</DropdownMenu>
								</div>
							</>}

					</nav>
				</header>

				<div className="flex-1">
					<Outlet />
				</div>

				<footer className="flex flex-col items-start justify-between bg-brand-600 px-12 py-8 text-white lg:flex-row">
					<p>WizWorld Marketplace - 2023 © All Rights Reserved</p>
					<div className="flex flex-col items-start lg:flex-row lg:items-end  lg:gap-4">
						<Link className="underline" to="/privacy-policy">
							Privacy Policy
						</Link>
						<Link className="underline" to="/terms-and-conditions">
							Terms and Conditions
						</Link>
					</div>
				</footer>
			</div>
			<Confetti id={data.confettiId} />
			<EpicToaster toast={data.toast} />
			<EpicProgress />
		</Document>);

}

function AppWithProviders() {
  const data = useLoaderData<typeof loader>();
  return (
    <AuthenticityTokenProvider token={data.csrfToken}>
			<HoneypotProvider {...data.honeyProps}>
				<App />
			</HoneypotProvider>
		</AuthenticityTokenProvider>);

}

export default withSentry(AppWithProviders);

export function ErrorBoundary() {
  // the nonce doesn't rely on the loader so we can access that
  const nonce = useNonce();

  // NOTE: you cannot use useLoaderData in an ErrorBoundary because the loader
  // likely failed to run so we have to do the best we can.
  // We could probably do better than this (it's possible the loader did run).
  // This would require a change in Remix.

  // Just make sure your root route never errors out and you'll always be able
  // to give the user a better UX.

  return (
    <Document nonce={nonce}>
			<GeneralErrorBoundary />
		</Document>);

}